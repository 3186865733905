import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Documents from '../src/pages/Documents';
import App from './scripts/app.jsx';

import './i18n';

const root = ReactDOM.createRoot(document.querySelector('.web-site'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/:docId" element={<Documents />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);
