import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Slick1 from '../img/ukf/prod/Мельпомена Таврії_2.jpg';
import Slick2 from '../img/ukf/prod/Одеський міжнародний кінофестиваль_1.jpeg';
import Slick3 from '../img/ukf/prod/Історична правда в деталях.jpg';
import Slick4 from '../img/ukf/prod/Наші Котики_1.jpg';
import Slick5 from '../img/ukf/prod/Ukrainian Live Classic_3.png';
import Slick6 from '../img/ukf/prod/На рівних_2.jpg';
import Slick7 from '../img/ukf/prod/Подкаст Пошук_1.png';

import '../scss/hwch.scss';
import '../scss/curusel.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

export function CarouselBox() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const { t } = useTranslation();

  return (
    <Carousel className="b_g_c" activeIndex={index} onSelect={handleSelect}>
      {/*flex-container 1*/}
      <Carousel.Item>
        <div className="carusel-cast-style">
          <div className="carusel-cast-style_item">
              <h2 className="h2-castem">{t("projects_slider.title")}</h2>
              <p className="main_txt">{t("projects_slider.info")}</p>
          </div>
          <div className="carusel-cast-style_img">
              <img className="slick_img" src={Slick1} alt="Second slide" />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carusel-cast-style">
          <div className=" carusel-cast-style_item">
              <h2 className="h2-castem">{t("projects_slider.title1")}</h2>
              <p className="main_txt">{t("projects_slider.info1")}</p>
          </div>
          <div className="carusel-cast-style_img">
            <img className="slick_img" src={Slick2} alt="Second slide" />
          </div>
        </div>
      </Carousel.Item>
        <Carousel.Item>
            <div className="carusel-cast-style">
                <div className="carusel-cast-style_item">
                  <h2 className="h2-castem">{t("projects_slider.title2")}</h2>
                  <p className="main_txt">{t("projects_slider.info2")}</p>
                </div>
                <div className="carusel-cast-style_img">
                  <img className="slick_img" src={Slick3} alt="Second slide" />
                </div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="carusel-cast-style">
          <div className="carusel-cast-style_item">
              <h2 className="h2-castem">{t("projects_slider.title3")}</h2>
              <p className="main_txt">{t("projects_slider.info3")}</p>
          </div>
          <div className="carusel-cast-style_img">
              <img className="slick_img" src={Slick4} alt="Second slide" />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carusel-cast-style">
          <div className=" carusel-cast-style_item">
              <h2 className="h2-castem">{t("projects_slider.title4")}</h2>
              <p className="main_txt">{t("projects_slider.info4")}</p>
          </div>
          <div className="carusel-cast-style_img">
            <img className="slick_img" src={Slick5} alt="Second slide" />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
          <div className="carusel-cast-style">
              <div className="carusel-cast-style_item">
                <h2 className="h2-castem">{t("projects_slider.title5")}</h2>
                <p className="main_txt">{t("projects_slider.info5")}</p>
              </div>
              <div className="carusel-cast-style_img">
                <img className="slick_img" src={Slick6} alt="Second slide" />
              </div>
          </div>
      </Carousel.Item>
      <Carousel.Item>
          <div className="carusel-cast-style">
              <div className="carusel-cast-style_item">
                <h2 className="h2-castem">{t("projects_slider.title6")}</h2>
                <p className="main_txt">{t("projects_slider.info6")}</p>
              </div>
              <div className="carusel-cast-style_img">
                <img className="slick_img" src={Slick7} alt="Second slide" />
              </div>
          </div>
      </Carousel.Item>
    </Carousel>
  );
}
