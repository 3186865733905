import React from 'react';
import '../scss/hwch.scss';
import { useTranslation } from 'react-i18next';

export function Bgcarousel() {
  const { t } = useTranslation();
  return (
    <div className="how-its-worck">
      <div className="how-its-worck_title">
        <span className="title">{t('project_report.title')}</span>
      </div>
    </div>
  );
}
